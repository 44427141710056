import {
  BOOKINGS_DEF_ID,
  MEMBERS_AREA_DEF_ID,
  PageId,
  MY_WALLET_DEF_ID,
} from '../constants';
import { getBookingsDefId } from './editor-sdk-actions';
import { IntegrationApplication } from '@wix/members-area-integration-kit/dist/src/types';
import { getAsset } from './get-asset';

export async function installMembersArea(sdk, appToken) {
  sdk.application.install(appToken, {
    appDefinitionId: MEMBERS_AREA_DEF_ID,
    initiatorAppDefinitionId: await getBookingsDefId(sdk)
  });
}

export async function addBookingsMembersArea(sdk, appToken) {
  const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: MEMBERS_AREA_DEF_ID});
  const appDefinitionId = await getBookingsDefId(sdk);

  return membersAPI.addApplications([
    {
      appDefinitionId: appDefinitionId,
      pageId: PageId.BOOKINGS_MEMBERS_AREA,
      social: false,
      showInLoginMenu: true,
      method: 'addSection',
    },
  ]);

}

export function membersAreaApplications(translate, currentVersion): IntegrationApplication[] {

  return [{
    appDefinitionId: BOOKINGS_DEF_ID,
    pageId: PageId.BOOKINGS_MEMBERS_AREA,
    showInMemberMenu: true,
    showInLoginMenu: true,
    menuOrder: 2,
    social: false,
    shouldNavigate: false,
    shouldInstallInitially: true,
    page: {
      name: translate('members-area.my-bookings.page.title'),
      shortDescription: translate('members-area.my-bookings.page.subtitle'),
      description: translate('members-area.my-bookings.page.description'),
      iconURL: getAsset(currentVersion, 'img/bookings-logo.png'),
      origin: translate('members-area.bookings.author'),
      isNew: false,
      screenshots: [
        getAsset(currentVersion, 'img/my-bookings.png'),
        getAsset(currentVersion, 'img/my-bookings-open.png'),
        getAsset(currentVersion, 'img/mobile-my-bookings.png'),
        getAsset(currentVersion, 'img/mobile-my-bookings-open.png'),
      ],
    }
  },
  {
    appDefinitionId: MY_WALLET_DEF_ID,
    pageId: PageId.MY_WALLET,
    showInMemberMenu: true,
    showInLoginMenu: true,
    social: false,
    socialHome: false,
    shouldNavigate: false,
    shouldInstallInitially: true,
    urlOverride: 'my-wallet',
    method: 'addApplication',
    page: {
      name: translate('members-area.my-wallet.page.title'),
      shortDescription: translate('members-area.my-wallet.page.subtitle'),
      description: translate('members-area.my-wallet.page.description'),
      iconURL: getAsset(currentVersion, 'img/bookings-logo.png'),
      origin: translate('members-area.my-wallet.author'),
      isNew: false,
      screenshots: [
        getAsset(currentVersion, 'img/my-wallet.png'),
        getAsset(currentVersion, 'img/my-wallet-open.png'),
        getAsset(currentVersion, 'img/mobile-my-wallet.png'),
        getAsset(currentVersion, 'img/mobile-my-wallet-open.png'),
      ],
    }
  }];
}
