export interface FullActionDefinition {
  actionId?: string;
  label: string;
}

export interface OverrideEditorActionDefinition {
  actionType?: IconButtonsActionType;
  label: string;
}

export const enum IconButtonsActionType {
  SETTINGS = 'settings',
  DESIGN = 'design',
  CROP = 'crop',
  FILTERS = 'filters',
  ANIMATION = 'animation',
  LINK = 'link',
  STRETCH = 'stretch',
  LAYOUT = 'layout',
  UPGRADE = 'upgrade',
  CONNECT = 'connect',
  PINMODE = 'pinMode',
  TEXTSIZE = 'textSize',
  HELP = 'help',
  CUSTOMCONNECT = 'customConnect',
  ADD = 'add',
}

export type ActionDefinition = FullActionDefinition | OverrideEditorActionDefinition | string;

export interface GfppAdditionalConfiguration {
  mainAction1?: ActionDefinition;
  mainAction2?: ActionDefinition;
  withoutLayout?: boolean;
  withHideability?: boolean;
  shouldHideActionsOnMobile?: boolean;
  desktopSettingsAction?: ActionDefinition;
}

export interface GfppDefinitions {
  mainAction1?: ActionDefinition;
  mainAction2?: ActionDefinition;
  iconButtons: {
    [IconButtonsActionType.SETTINGS]?: ActionDefinition;
    [IconButtonsActionType.DESIGN]?: ActionDefinition;
    [IconButtonsActionType.CROP]?: ActionDefinition;
    [IconButtonsActionType.FILTERS]?: ActionDefinition;
    [IconButtonsActionType.ANIMATION]?: ActionDefinition;
    [IconButtonsActionType.LINK]?: ActionDefinition;
    [IconButtonsActionType.STRETCH]?: ActionDefinition;
    [IconButtonsActionType.LAYOUT]?: ActionDefinition;
    [IconButtonsActionType.UPGRADE]?: ActionDefinition;
    [IconButtonsActionType.CONNECT]?: ActionDefinition;
    [IconButtonsActionType.PINMODE]?: ActionDefinition;
    [IconButtonsActionType.TEXTSIZE]?: ActionDefinition;
    [IconButtonsActionType.HELP]?: ActionDefinition;
    [IconButtonsActionType.CUSTOMCONNECT]?: FullActionDefinition;
    [IconButtonsActionType.ADD]?: ActionDefinition;
  },
  helpId?: string,
}

export class ConnectionBuilder {
  private gfppDefs: GfppDefinitions = {
    iconButtons: {},
  };
  private mobileGfppDefs: GfppDefinitions = {
    iconButtons: {},
  };

  private connection = {
    behavior: {
      duplicatable: true,
      rotatable: true,
      pinnable: true,
      toggleShowOnAllPagesEnabled: true,
      canReparent: true,
      resizable: true,
      dataEditOptions: 'FULL',
      preventHide: true,
      closed: {selectable: true}
    },
    firstTimeExperience: {
      hidden: false
    },
    connections: {},
    displayName: null,
    nickname: null
  };

  withMainAction1(mainAction: ActionDefinition, shouldDisplayOnMobile = true) {
    this.gfppDefs.mainAction1 = mainAction;
    if (shouldDisplayOnMobile) {
      this.mobileGfppDefs.mainAction1 = mainAction;
    }
    return this;
  }

  withMainAction2(mainAction: ActionDefinition, shouldDisplayOnMobile = true) {
    this.gfppDefs.mainAction2 = mainAction;
    if (shouldDisplayOnMobile) {
      this.mobileGfppDefs.mainAction2 = mainAction;
    }
    return this;
  }

  withConnections(connections: any) {
    this.connection.connections = connections;
    return this;
  }

  withHelpId(helpId: string) {
    this.gfppDefs.helpId = helpId;
    this.mobileGfppDefs.helpId = helpId;
    return this;
  }

  withDisabledTextEditing() {
    this.connection.behavior.dataEditOptions = 'TEXT_STYLE_ONLY';
    return this;
  }

  withDisabledResizability() {
    this.connection.behavior.resizable = false;
    return this;
  }

  withDisabledRotatability() {
    this.connection.behavior.rotatable = false;
    return this;
  }

  withDisabledDuplicability() {
    this.connection.behavior.duplicatable = false;
    return this;
  }

  withDisabledDataConnection() {
    this.gfppDefs.iconButtons.connect = 'HIDE';
    this.mobileGfppDefs.iconButtons.connect = 'HIDE';
    return this;
  }

  withDisabledAnimation() {
    this.gfppDefs.iconButtons.animation = 'HIDE';
    this.mobileGfppDefs.iconButtons.animation = 'HIDE';
    return this;
  }

  withDisabledLinkAbility() {
    this.gfppDefs.iconButtons.link = 'HIDE';
    this.mobileGfppDefs.iconButtons.link = 'HIDE';
    return this;
  }

  withoutAddElement() {
    this.gfppDefs.iconButtons.add = 'HIDE';
    this.mobileGfppDefs.iconButtons.add = 'HIDE';
    return this;
  }

  withDisabledSettings() {
    this.gfppDefs.iconButtons.settings = 'HIDE';
    this.mobileGfppDefs.iconButtons.settings = 'HIDE';
    return this;
  }

  withDisabledDesign() {
    this.gfppDefs.iconButtons.design = 'HIDE';
    this.mobileGfppDefs.iconButtons.design = 'HIDE';
    return this;
  }

  withDisabledLayout() {
    this.gfppDefs.iconButtons.layout = 'HIDE';
    this.mobileGfppDefs.iconButtons.layout = 'HIDE';
    return this;
  }

  withEnableHideability() {
    this.connection.behavior.preventHide = false;
    return this;
  }

  withoutHelpUrl() {
    this.gfppDefs.iconButtons.help = 'HIDE';
    this.mobileGfppDefs.iconButtons.help = 'HIDE';
    this.gfppDefs.helpId = '';
    this.mobileGfppDefs.helpId = '';
    return this;
  }

  withoutCropOption() {
    this.gfppDefs.iconButtons.crop = 'HIDE';
    this.mobileGfppDefs.iconButtons.crop = 'HIDE';
    return this;
  }

  withoutFirstTimeModal() {
    this.connection.firstTimeExperience.hidden = true;
    return this;
  }

  withoutMobileActions() {
    delete (this.mobileGfppDefs.mainAction1);
    delete (this.mobileGfppDefs.mainAction2);
    return this;
  }

  withLabel(label: string) {
    this.connection.displayName = label;
    return this;
  }

  withNickname(nickname: string) {
    this.connection.nickname = nickname;
    return this;
  }

  withDisableSelection() {
    this.connection.behavior.closed = {selectable: false};
    return this;
  }

  fullyDisabled() {
    this
      .withMainAction1('HIDE')
      .withMainAction2('HIDE')
      .withoutHelpUrl()
      .withDisabledTextEditing()
      .withDisabledResizability()
      .withDisabledDuplicability()
      .withDisabledAnimation()
      .withDisabledDesign()
      .withDisabledSettings();
    return this;
  }

  withSettingsActionOnDesktop(action: ActionDefinition) {
    this.gfppDefs.iconButtons.settings = action;
    this.mobileGfppDefs.iconButtons.settings = 'HIDE';
  }

  build() {
    return {
      ...this.connection,
      gfpp: {
        desktop: this.gfppDefs,
        mobile: this.mobileGfppDefs,
      }
    }
  }
}

export class ConnectionBuilderFactory {
  constructor(private translation: any) {
  }

  public uneditableText = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withDisabledDuplicability()
      .withDisabledResizability()
      .withDisabledLinkAbility()
      .withDisabledDataConnection()
      .withDisabledTextEditing()
      .withHelpId(helpId)
    if (additionalConfiguration.withHideability) {
      connection.withEnableHideability();
    }
    if (additionalConfiguration.mainAction2) {
      connection.withMainAction2(additionalConfiguration.mainAction2, !additionalConfiguration.shouldHideActionsOnMobile);
    }
    if (additionalConfiguration.shouldHideActionsOnMobile) {
      connection.withoutMobileActions();
    }
    return connection.build();
  }

  public link = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withDisabledDuplicability()
      .withDisabledResizability()
      .withDisabledLinkAbility()
      .withDisabledDataConnection()
      .withoutHelpUrl()
      .withHelpId(helpId)
    if (additionalConfiguration.withHideability) {
      connection.withEnableHideability();
    }
    if (additionalConfiguration.mainAction1) {
      connection.withMainAction1(additionalConfiguration.mainAction1, !additionalConfiguration.shouldHideActionsOnMobile)
    }
    if (additionalConfiguration.shouldHideActionsOnMobile) {
      connection.withoutMobileActions();
    }
    return connection.build();
  }

  public linkDisabledText = (label: string = null, helpId: string = null) =>
    new ConnectionBuilder()
      .withLabel(label)
      .withMainAction1({
        label: this.translation['bookings-pages.editor-gfpp.action-design'] || 'Change Design',
        actionType: IconButtonsActionType.DESIGN,
      })
      .withDisabledDuplicability()
      .withDisabledResizability()
      .withDisabledLinkAbility()
      .withDisabledDataConnection()
      .withDisabledTextEditing()
      .withoutHelpUrl()
      .withHelpId(helpId)
      .withoutMobileActions()
      .build();

  public repeater = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withDisabledDuplicability()
      .withDisabledResizability()
      .withDisabledLinkAbility()
      .withDisabledDataConnection()
      .withoutHelpUrl()
      .withHelpId(helpId)
      .withDisabledAnimation()
      .withMainAction1(additionalConfiguration.mainAction1, !additionalConfiguration.shouldHideActionsOnMobile);
    if (additionalConfiguration.mainAction2) {
      connection.withMainAction2(additionalConfiguration.mainAction2, !additionalConfiguration.shouldHideActionsOnMobile);
    }
    if (additionalConfiguration.withoutLayout) {
      connection.withDisabledLayout()
    }
    return connection.build();
  }

  public repeaterItem = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withDisabledDuplicability()
      .withDisabledResizability()
      .withDisabledLinkAbility()
      .withDisabledDataConnection()
      .withoutHelpUrl()
      .withHelpId(helpId);

    if (additionalConfiguration.mainAction2) {
      connection.withMainAction2(additionalConfiguration.mainAction2, !additionalConfiguration.shouldHideActionsOnMobile);
    }
    return connection.build();
  }

  public editableText = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withDisabledDuplicability()
      .withDisabledResizability()
      .withDisabledLinkAbility()
      .withDisabledDataConnection();
    if (additionalConfiguration.withHideability) {
      connection.withEnableHideability();
    }
    if (helpId) {
      connection.withHelpId(helpId);
    }
    return connection.build();
  };

  public textBox = (label: string = null) => new ConnectionBuilder()
    .withLabel(label)
    .withoutHelpUrl()
    .build();

  public divider = (label: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withoutHelpUrl()
    if (additionalConfiguration.withHideability) {
      connection.withEnableHideability();
    }
    return connection.build();
  }

  public unselectableBox = () => new ConnectionBuilder().withDisableSelection().build();

  public image = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => new ConnectionBuilder()
    .withLabel(label)
    .withoutHelpUrl()
    .withHelpId(helpId)
    .withDisabledDataConnection()
    .withDisabledLinkAbility()
    .withDisabledSettings()
    .withDisabledDuplicability()
    .withDisabledResizability()
    .withDisabledRotatability()
    .withoutCropOption()
    .withEnableHideability()
    .withMainAction1(additionalConfiguration.mainAction2, !additionalConfiguration.shouldHideActionsOnMobile)
    .build();

  public stateBox = (label: string = null, helpId: string = null, additionalConfiguration: GfppAdditionalConfiguration = {}) => {
    const connection = new ConnectionBuilder()
      .withLabel(label)
      .withDisabledAnimation()
      .withDisabledDesign()
      .withHelpId(helpId)
      .withMainAction2(additionalConfiguration.mainAction2, !additionalConfiguration.shouldHideActionsOnMobile)
      .withoutFirstTimeModal();
    if (additionalConfiguration.desktopSettingsAction) {
      connection.withSettingsActionOnDesktop(additionalConfiguration.desktopSettingsAction)
    } else {
      connection.withDisabledSettings()
    }
    return connection.build();
  }

  public stateBoxState = (label: string = null) => new ConnectionBuilder()
    .withLabel(label)
    .withoutHelpUrl()
    .build();

  public loaderImage = (label: string = null) => new ConnectionBuilder()
    .withLabel(label)
    .withDisabledSettings()
    .withDisabledLinkAbility()
    .withDisabledDataConnection()
    .build();
}
